.form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .form-field {
    width: 100%;
  }
}
.form-field {
  display: block;
}
