
* {
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
}
