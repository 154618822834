@media only screen and (max-width: 1366px) {
  .side-bar .content .content:last-of-type {
    padding: 40px 40px 40px 30px !important;
  }

  .banner {
    height: auto !important;
    display: inline-flex !important;
    flex-direction: row !important;
  }

  .banner .metric {
    width: auto !important;
    min-width: 15% !important;
    padding: 5px !important;
    margin: 10px !important;
    word-break: break-word !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .table .table-row-head th {
    white-space: normal !important;
    line-height: 20px !important;
    padding: 5px !important;
  }

    // batch
    .upload-recipients-panel-body {
      margin: 35px 0px 35px 0px !important
    }

    .upload-recipients-panel,
    .add-recipients-panel,
    .schedule-delivery-panel,
    .send-sms-question-panel,
    .payment-panel  {
      width: auto !important;
    }

    .show-more-container {
      padding-top: 0px !important;
      margin-bottom: 20px !important;
    }

    .show-more-container .show-more {
      display: flex !important;
      flex-direction: row !important;
      width: 100% !important;
      justify-content: center !important;
      border-bottom: 1px solid #000 !important;
      padding: 25px 0px 25px 0px !important;
    }

    .error-msg {
      word-break: break-word;
    }
}

@media only screen and (max-width: 1024px) {
  .program-detail-heading {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 12px !important;
    flex-direction: row !important;
    text-align: center !important;
  }

  // batch creation
  .upload-recipients-panel,
  .add-recipients-panel,
  .schedule-delivery-panel,
  .send-sms-question-panel  {
    padding: 38px 20px  !important;
    //width: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding: 0px !important
  }

  .banner {
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: inherit !important;
  }

  .banner .metric {
    width: auto !important;
    word-break: break-word !important;
    display: flex !important;
    flex-direction: row !important;
    margin: 10px 0px !important;
    padding: 10px 40px !important;
  }


  /* dashboard */
  .top-bar .left-container {
    margin-top: 7px !important;
  }

  .top-bar .left-container .logo {
    margin-left: 90px !important;
  }

  .top-bar .left-container .strap-line {
    margin: 0px 30px !important;
    display: none;
  }

  .top-bar .right-container .label {
    margin-right: 10px !important;
    font-size: 18px !important;
    padding: 0px 20px !important;
  }

  .side-bar {
    flex-direction: column !important;
    width: 100%;
  }
  .side-bar .menu .header .title {
    margin: 22px 22px 22px 40px!important;
    font-size: 20px !important;
  }

  .side-bar .content .content:last-of-type {
    padding: 0px !important
  }

  .banner {
    flex-direction: column !important;
    height: auto !important;
    margin-bottom: 25px !important;
  }

  .divider {
    margin: 0px !important;
  }

  .feature-title {
    padding: 40px 20px !important;
    text-align: center !important;
  }

  .section-title {
    padding: 20px !important;
    margin: 0px 0px 20px 0px !important;
    text-align: center !important;
    border-top: 1px solid #00a7ff !important;
    border-bottom: 1px solid #00a7ff !important;
  }

  .program-title {
    text-align: center !important;
    padding: 5px 15px 20px 0px !important;
    width: 100% !important;
    margin: 0px !important;
}

  .banner .metric {
      border-bottom: 1px solid !important;
  }

  .program-detail {
    margin-top: 15px !important;
  }

  .program-detail-heading {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 12px !important;
    flex-direction: column !important;
    text-align: center !important;
  }

  .program-detail-heading .program-detail-heading-text {
    width: 100% !important;
    border-bottom: 1px solid #000 !important;
    padding: 0px 0px 20px 0px !important;
  }
  .program-detail-heading .button {
    margin: 30px 0px 20px 0px !important;
    // padding: 20px 0px 40px 0px !important;
    min-width: 580px;
    font-size: 18px !important
  }

  .button.sm {
    min-width: 580px !important;
    font-size: 18px !important;
    height: 70px !important;
  }

  /* side menu */

  .side-bar {
    flex-direction: column !important;
    min-height: 100vh;
    &.collapsed {
      height: 0px !important;
      & > .label {
        display: none;
      }
    }
  }

  .nav-list-item .label {
    font-size: 18px !important;
  }

  .menu {
    max-height: 1200px;
    transition: margin-left 400ms ease-out !important;
    transition: max-height 400ms ease-in !important;
    &.collapsed {
      margin-left: -1000px !important;
      background-color: red;
      max-height: 0px;
      transition: margin-left 1000ms ease-in !important;
      transition: max-height 400ms ease-out !important;
    }
  }

  /* eml dashboard specifics */

  app-eml-dashboard div:first-child div:first-child{
    display: flex !important;
    flex-direction: column !important;
    .button-group {
      justify-content: center;
      .button {
        min-width: 200px
      }
    }
  }

  app-eml-dashboard table tr :nth-child(1) {
    width:100px
  }

  app-eml-dashboard table tr :nth-child(2),
  app-eml-dashboard table tr :nth-child(3)  {
    display: none;
  }

  app-eml-dashboard table tr {
    .button-group .button {
      min-width: 130px !important;
      margin: 5px !important;
    }
  }

  /* batch status specifics */
  app-batch-status-table table {
    margin: 0px 20px;
    width: auto !important;
  }

  app-batch-status-table .table-row-head th {
      padding: 5px 10px !important;
      text-align: center !important;
      white-space: normal !important;
      width: 20% !important;
      line-height: normal !important;
  }

  app-batch-status-table tr td {
    padding: 0px !important;
  }

  app-batch-status-table tr td.amount,
  app-batch-status-table tr td.created-date,
  app-batch-status-table tr td.status,
  app-batch-status-table tr td.action {
    text-align: center !important;
  }

  app-batch-status-table .table-row-body-data {
    height: 60px !important
}

  .batch-status {
    display: inline !important;
  }

  app-batch-status-table .table-row-body-data td.action .icon {
    display: inline !important;
  }

  .batch-status-panel-footer{
    padding-top: 15px !important;
    margin-bottom: 40px !important;
}

  .button-group .button {
    margin: 0 !important;
    height: 70px !important;
    font-size: 18px !important;
  }

  /* Create batch */

  form {
    font-size: 18px !important;
  }

  .page .step-panel-body {
    margin-right: 30px !important;
  }
  .page .step-panel-heading .step-indicator[class*="step-"],
  .page .step-panel-body .step-connector {
    margin-left: 10px;
  }

  .page .step-panel-body .step-content .button-group {
    display: flex;
    flex-direction: column;
  }

  .add-recipients-panel .button,
  .page .step-panel-body .step-content .button-group .button {
    margin: 0px 0px 30px 0px !important;
    // padding: 20px 0px 40px 0px !important;
    min-width: 580px;
    font-size: 18px !important;
    height: 70px !important;
  }

  .step-panel-body .step-content .button + .button {
    margin: 0 !important
  }

  .upload-recipients-panel,
  .add-recipients-panel,
  .schedule-delivery-panel,
  .send-sms-question-panel  {
    padding: 38px 20px  !important;
    width: auto !important;
}

  .step-panel-body .step-content {
    margin-left: 10px !important;
  }

  .add-recipients-panel .recipients-form .recipient-field-group,
  .add-recipients-panel .recipients-form-readonly .recipient-field-group {
    flex-direction: column !important;
  }

  .add-recipients-panel .recipients-form .recipient-field-group > *,
  .add-recipients-panel .recipients-form-readonly .recipient-field-group > * {
    margin-right: 0px !important
  }

  .add-recipients-panel .add-recipients-panel-footer {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  .add-recipients-panel .recipients-form-readonly .wrapper {
    width: auto !important;
    font-size: 18px !important;
  }
  .mat-form-field {
    width: 100% !important
  }

  .upload-recipients-panel .subheading {
    text-align: center !important;
  }

  .upload-recipients-panel-footer {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  .upload-recipients-panel-body {
    margin: 30px 0px !important;
  }

  .batch-status-panel {
    margin-top: 30px !important;
  }

  .batch-status-panel-heading {
    text-align: center;
  }

  .schedule-delivery-panel-body {
    margin: 35px 0px 35px 0px !important;
  }

  .schedule-delivery-panel-body .delivery-choice .option {
    margin-bottom: 15px !important;
  }
  .schedule-delivery-panel-footer {
    justify-content: center !important;
  }

  // success panel

  .payment-panel {
    padding: 38px 20px !important;
    width: auto !important;
  }

  .payment-panel-body {
    margin: 0 !important;
  }

  .payment-panel-footer {
    justify-content: center !important;
  }
  .payment-panel-body .schedule-successful .detail-rows .detail-row .transaction-value,
  .payment-panel-body .schedule-successful .detail-rows .detail-row .transaction-count {
    font-size: 18px !important
  }

  app-client-dashboard .col-8 {
    flex: auto !important;
    width: auto !important;
    word-break: break-word !important;
    display: flex !important;
    flex-direction: row !important;
    margin: 10px 0 !important;
    padding: 10px 40px !important;
    max-width: 100% !important;
      .mat-form-field {
        text-align: center;
      }
    }
  }

@media only screen and (max-width: 600px) {
  .button.sm {
    min-width: 280px !important;
  }

  .program-detail-heading .button {
    min-width: 280px;
  }
  .add-recipients-panel .button,
  .page .step-panel-body .step-content .button-group .button {
    min-width: 280px;
  }

  app-batch-status-table table {
    margin: 0px !important;
    width: 100% !important;
  }

  app-batch-status-table tr :nth-child(2),
  app-batch-status-table tr :nth-child(3) {
    display: none
  }
}

@media only screen and (max-width: 375px) {
  .button.sm {
    min-width: 200px !important;
  }

  .program-detail-heading .button {
    min-width: 200px !important;
  }
  .add-recipients-panel .button,
  .page .step-panel-body .step-content .button-group .button {
    min-width: 200px !important;
  }

  .program-title .program-title-type {
    display: flex;
    flex-flow: column;
    margin-left: auto !important;
  }

  .program-detail-heading .button {
    min-width: 200px;
  }
  .add-recipients-panel .button,
  .page .step-panel-body .step-content .button-group .button {
    min-width: 200px;
  }
}
